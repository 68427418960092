@font-face {
  font-family: "gerbil";
  src: url("../../font/gerbil.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Neris";
  src: url("../../font/Neris/Neris-Black.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Neris";
  src: url("../../font/Neris/Neris-Thin.otf");
  font-weight: 300;
}

.popup {
  position: fixed;
  z-index: 2;
  background-color: rgba(4, 23, 22, 0.5);
  backdrop-filter: blur(15px);
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.popup-content {
  position: absolute;
  background-color: var(--font-white);
  width: 60%;
  height: 598px;
  left: 19%;
  top: 12%;
  border-radius: 20px;
  text-align: center;
  background-image: url(../../images/Group\ 51.png),
    url(../../images/Group\ 418.png);
  background-repeat: no-repeat, no-repeat;
  background-position: 90% 0%, 15% 0%;
}

.popup-content > .close-container {
  position: absolute;
  width: 46.34px;
  height: 46.34px;
  border-radius: 50%;
  left: 907.95px;
  top: -38.79px;
  background-color: var(--font-white);
  cursor: pointer;
  display: flex;

  justify-content: center;
  align-items: center;
}

.popup-close svg {
  display: inline-block;
  height: 27px;
  width: 27px;
  /* border: 1px solid red; */
  /* color: red !important; */
  /* padding-top: 0.1rem;
  font-size: 36px; */
}

.popup-header {
  height: 225px;
  background-image: url(../../images/Group\ 420.png),
    url(../../images/Group\ 419.png), var(--bg-popup);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: 7% 0%, 21.5% -19%;
  border-radius: 20px 20px 0px 0px;
  padding-top: 7rem;
  color: var(--font-white);
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
  font-family: "gerbil", sans-serif;
}

.popup-body {
  padding: 3rem 0;
}

.popup-body > p:first-child {
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  font-family: "Neris", sans-serif;
}

.popup-body > p:last-child {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  font-family: "Neris", sans-serif;
}

.popup-footer > input {
  width: 564.07px;
  height: 53.69px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  padding: 0 2rem;
  outline: none;
  border: none;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  font-family: "Neris", sans-serif;
  color: var(--font-color-dark);
}

.popup-footer > button {
  width: 133.97px;
  height: 53.69px;
  background-color: var(--font-yellow);
  color: var(--font-white);
  border-radius: 50px;
  margin-left: 2rem;
  box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.25);
  outline: none;
  border: none;
  font-size: 20px;
  line-height: 27px;
  font-family: "Neris", sans-serif;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (min-width: 2560px) {
  .popup-content {
    width: 50%;
    left: 24%;
    top: 20%;
    border-radius: 20px;
  }

  .popup-content > .close-container {
    left: 99%;
    top: -6%;
  }
}

@media screen and (max-width: 1440px) {
  .popup-content {
    width: 60%;
    height: 598px;
    left: 17%;
    top: 20%;
    border-radius: 20px;
  }

  .popup-content > .close-container {
    left: 99%;
    top: -6%;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1025px) {
  .popup-content {
    width: 77%;
    height: 598px;
    left: 13%;
    top: 15%;
    border-radius: 20px;
  }

  .popup-content > .close-container {
    left: 99%;
    top: -6%;
  }

  .popup-footer > input {
    width: 67%;
  }

  .popup-footer > button {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .popup-content {
    width: 85%;
    height: 598px;
    left: 10%;
    top: 15%;
    border-radius: 20px;
  }

  .popup-content > .close-container {
    left: 99%;
    top: -6%;
  }
}

@media screen and (max-width: 768px) {
  .popup-content {
    width: 85%;
    height: 598px;
    left: 8%;
    top: 10%;
    border-radius: 20px;
  }

  .popup-header {
    padding-top: 4rem;
  }

  .popup-header > p {
    width: 70%;
    text-align: center;
    margin: 0 auto;
  }

  .popup-header {
    height: 225px;
    background-image: url(../../images/Group\ 420.png),
      url(../../images/Group\ 419.png), var(--bg-popup);
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 3% 0%, 28.5% -19%;
  }

  .line-break::before {
    content: "\a";
    white-space: pre;
  }

  .popup-content > .close-container {
    left: 634.95px;
  }

  .popup-footer {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .popup-footer > input {
    width: 90%;
    margin-bottom: 1rem;
  }

  .popup-footer > button {
    margin-left: 0;
  }
}

.wallet-connect {
  background: white;
}
.wallet-connect section {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: inherit;
  justify-content: space-around;
  align-items: center;
}
.wallet-connect section .left-side {
  height: 100%;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}

.wallet-connect section .left-side img {
  width: 322.2px;
  height: 368.98px;
  border-radius: 17.735px;
  object-fit: cover;
}

.fade-in-image {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wallet-connect section .right-side {
  display: flex;
  flex-direction: column;

  height: 90%;
  width: 50%;
  border: 0.85013px solid #e7e9f0;
  box-shadow: 0px 0px 8.5013px #e7e9f0;
  border-radius: 17.0026px;
  padding: 50px 30px;
  text-align: left;
}

.wallet-connect section .right-side h3 {
  font-family: "Gerbil";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #041716;
  text-align: left;
}
.wallet-connect section .right-side p {
  font-family: "M PLUS 1";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #041716;
}
.wallet-connect section .right-side .ethereum {
  background: rgba(231, 233, 240, 0.5);
  width: 119px;
  height: 30.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 7px;
  /* filter: blur(2px); */
  border-radius: 9.42535px;
  /* typography */
  font-family: "M PLUS 1";
  font-style: normal;
  font-weight: 400;
  font-size: 7.54028px;
  line-height: 14px;
  text-align: center;
  color: #4d5263;
}

.wallet-connect section .right-side span {
  color: rgba(20, 114, 255, 1);
}
.wallet-connect section .wallet-connect-item {
  width: 100%;
  height: 53.56px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 0.75689px solid rgba(255, 174, 36, 0.5);
  border-radius: 7.5689px;
  margin-top: 17px;
  padding-left: 30px;
  cursor: pointer;
}
.wallet-connect section .wallet-connect-name {
  font-family: "M PLUS 1";
  font-style: normal;
  font-weight: 400;
  font-size: 13.6021px;
  line-height: 22px;
  color: #000000;
  padding-left: 21px;
}

/* .wallet-connect section .right-side .wallet-connect-button {
  width: 100%;
  display: flex;
  align-self: baseline;
  justify-content: center;
  align-items: center;
  height: 53px;
  background: #ffffff;
  border: 0.75689px solid #ffae24;
  border-radius: 7.5689px;
} */
@media screen and (max-width: 787px) {
  .popup-content > .close-container {
    /* position: absolute;
    width: 20.34px;
    height: 20.34px;
    border-radius: 50%;
    left: 97%;
    top: -7%;
    background-color: var(--font-white);
    cursor: pointer; */
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20.34px;
    height: 20.34px;
    border-radius: 50%;
    left: 100%;
    top: -5%;
    background-color: var(--font-white);
    cursor: pointer;
  }

  .popup-close svg {
    height: 100%;
    width: 100%;
    /* display: inline-block; */
    /* padding-bottom: 3rem; */
    /* font-size: 17px;
    font-weight: 900;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    margin: 0; */
  }

  .popup-footer {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .wallet-connect section {
    padding: 50px 0;
  }

  .wallet-connect section .right-side {
    /* display: none; */
    width: 90%;
  }
  .wallet-connect section .left-side {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .popup {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup-content {
    /* width: 90%; */
    height: max-content;
    margin: 0 auto;
    border-radius: 8px;
    left: 5%;
    top: 15%;
    /* background-image: url(../../images/Group\ 426.png);
    background-repeat: no-repeat;
    background-position: 13% 1%; */
  }

  .popup-header {
    border-radius: 8px 8px 0 0;
    padding-top: 3rem;
    height: 141px;
    background-image: url(../../images/Group\ 51.png),
      url(../../images/Group\ 424.png), url(../../images/Group\ 423.png),
      var(--bg-popup);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 100% 0%, 1% 0%, 27.5% 2%;
    background-size: contain, auto, auto, auto;
  }

  .popup-header > p {
    width: 70%;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    margin: 0 auto;
  }

  .line-break::before {
    content: "\a";
    white-space: pre;
  }

  .popup-body {
    padding: 2rem 0;
  }

  .popup-body > p:first-child {
    font-size: 15px;
    line-height: 12px;
    padding-bottom: 1rem;
  }

  .popup-body > p:last-child > br {
    display: none;
  }

  .popup-body > p:last-child {
    width: 80%;
    margin: 0 auto;
    font-size: 15px;
    line-height: 20px;
  }

  .popup-footer > input {
    width: 90%;
    margin-bottom: 1rem;
  }

  .popup-footer > button {
    margin-left: 0;
  }

  .popup-content > .close-container {
    /* position: absolute;
    width: 20.34px;
    height: 20.34px;
    border-radius: 50%;
    left: 97%;
    top: -7%;
    background-color: var(--font-white);
    cursor: pointer; */
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20.34px;
    height: 20.34px;
    border-radius: 50%;
    left: 97%;
    top: -17%;
    background-color: var(--font-white);
    cursor: pointer;
  }

  .popup-close {
    /* display: inline-block; */
    /* padding-bottom: 3rem; */
    font-size: 17px;
    font-weight: 900;
  }

  .popup-footer {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .wallet-connect section {
    padding: 50px 0;
  }

  .wallet-connect section .right-side {
    /* display: none; */
    width: 90%;
  }
  .wallet-connect section .left-side {
    display: none;
  }
}
