@font-face {
  font-family: "gerbil";
  src: url("../../font/gerbil.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Neris";
  src: url("../../font/Neris/Neris-Light.otf");
  font-weight: 300;
}

.first-feature_bg {
  background-image: url(../../images/Group\ 41.png), var(--bg-color);
  background-repeat: no-repeat, no-repeat;
  background-position: bottom right, 100% 100%;
  /* background-size: 100%, 470px; */
}

.header-text {
  height: 301.6px;
  background-image: var(
    --bg-white
  ); /* linear-gradient(233.27deg, rgba(252, 164, 161, 0.541) 28.63%, rgba(125, 167, 248, 0.24) 101.33%); */
  /* background-color: var(--font-white); */
  text-align: center;
  padding: 4rem 2rem;
  justify-content: center;
}

.header-text > h2 {
  font-weight: 400;
  font-family: "gerbil", sans-serif;
  color: var(--font-green);
  height: 60px;
  width: 579px;
  font-size: 50px;
  line-height: 60px;
}

.feature {
  padding-top: 12rem;
  padding-bottom: 26rem;
}

.first-feature {
  height: 899px;
  background-image: url(../../images/wallet\ 1.png);
  background-repeat: no-repeat, no-repeat;
  background-position: bottom left;
  /* background-size: 100%, 470px; */
}

.first > .main-text,
.third > .main-text,
.first > .sub-text,
.third > .sub-text {
  padding-left: 2rem;
}

.first > .sub-text {
  padding-bottom: 3rem;
  background-image: url(../../images/Group\ 31.png);
  background-repeat: no-repeat;
  background-position: 0% 100%;
  font-family: "M PLUS 1";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */
}

.second-feature {
  height: 902px;
  padding-top: 19rem;
  padding-bottom: 28rem;
  flex-direction: row-reverse;
  background-image: url(../../images/Group\ 412.png),
    url(../../images/ape\ 1.png);
  background-repeat: no-repeat, no-repeat;
  background-position: top left, bottom right;
}

.second > .main-text {
  padding-left: 17rem;
}

.second > .sub-text {
  padding-left: 17rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  background-image: url(../../images/Group\ 30.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  font-family: "M PLUS 1";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.third-feature {
  height: 914.4px;
  padding-top: 19rem;
  padding-bottom: 28rem;
  background-image: url(../../images/Group\ 413.png),
    url(../../images/conversion\ 2.png), var(--bg-color-2);
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
}

.third > .sub-text {
  padding-bottom: 3rem;
  background-image: url(../../images/Group\ 31.png);
  background-repeat: no-repeat;
  background-position: 0% 100%;
  font-family: "M PLUS 1";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */
}

.main-text {
  font-weight: 400;
  font-family: "gerbil", sans-serif;
  font-size: 50px;
  line-height: 60px;
  color: var(--font-color-dark);
  margin-bottom: 2rem;
}

.sub-text {
  font-weight: 300;
  font-family: "Neris", sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: var(--font-color-dark);
}

.mobile-image {
  display: none;
}

@media screen and (max-width: 1024px) {
  .first-feature_bg {
    background-image: url(../../images/Group\ 41.png), var(--bg-color);
    background-position: bottom right;
    background-size: 470px, cover;
  }

  .first-feature {
    height: 600px;
    background-image: url(../../images/wallet\ 1.png);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom left;
    background-size: 400px;
  }

  .second-feature {
    height: 600px;
    background-image: url(../../images/Group\ 412.png),
      url(../../images/ape\ 1.png);
    background-repeat: no-repeat, no-repeat;
    background-position: top left, bottom right;
    background-size: contain, 470px;
  }

  .third-feature {
    height: 600px;
    background-image: url(../../images/Group\ 413.png),
      url(../../images/conversion\ 2.png), var(--bg-color-2);
    background-repeat: no-repeat, no-repeat;
    background-position: top right, bottom left;
    background-size: 440px, 400px, cover;
  }
}

@media screen and (max-width: 768px) {
  .header-text {
    width: 100%;
    padding: 1rem;
    height: 134px;
    justify-content: center;
  }

  .first-feature {
    background-image: none;
  }

  .first-feature_bg {
    background-image: url(../../images/Group\ 61.svg), var(--bg-color);
    background-size: contain;
    background-position: 100% 80%;
    background-size: contain, cover;
  }

  .feature {
    height: 412px;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  /* .header-text>h2 {
    width: 132px;
    font-size: 20px;
    line-height: 25px;
    padding-bottom: 1rem;
  } */

  .text-container.first {
    width: 100%;
  }

  .main-text {
    font-size: 43px;
    line-height: 48px;
  }

  .first > .main-text,
  .third > .main-text {
    padding-left: 0;
  }

  .first > .sub-text,
  .first > .main-text,
  .third > .sub-text {
    padding-left: 1rem;
  }

  .sub-text {
    font-size: 15px;
    line-height: 20px;
    font-family: "M PLUS 1";
    font-style: normal;
    font-weight: 400;
  }

  .first > .sub-text {
    padding-bottom: 2rem;
    background-image: url(../../images/Group\ 31.svg);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: contain;
  }

  .mobile-image {
    display: block;
    padding: 2rem 1rem;
  }

  .second > .mobile-image {
    padding: 0 1rem;
  }

  .second-feature {
    height: 412px;
    flex-direction: row-reverse;
    background-image: url(../../images/Frame\ 62.svg);
    background-repeat: no-repeat;
    background-position: 0% 30%;
    background-size: contain;
  }

  /* .second-feature.feature {
    padding-top: 1rem;
  } */

  .second > .main-text {
    padding-left: 1rem;
  }

  .second > .sub-text {
    width: 100%;
    padding-left: 1rem;
    padding-right: 0rem;
    padding-bottom: 2rem;
    background-image: url(../../images/Group\ 60.svg);
    background-repeat: no-repeat;
    background-position: 0% 74%;
    background-size: contain;
  }

  .second > .sub-text > br,
  .third > .sub-text > br {
    display: none;
  }

  .third-feature {
    height: 412px;
    background-image: url(../../images/Group\ 63.svg), var(--bg-color-2);
    background-repeat: no-repeat, no-repeat;
    background-position: top right;
    background-size: contain;
  }

  .third > .main-text {
    padding-left: 1rem;
  }

  .third > .sub-text {
    padding-bottom: 2rem;
    background-image: url(../../images/Group\ 31.svg);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: contain;
  }
}

@media screen and (max-width: 640px) {
  .header-text {
    width: 100%;
    padding: 1rem;
    height: 134px;
    justify-content: center;
  }

  .header-text > div > img {
    max-width: 43.5px;
  }

  .first-feature {
    background-image: none;
  }

  .first-feature_bg {
    background-image: url(../../images/Group\ 61.svg), var(--bg-color);
    background-size: contain;
    background-position: 100% 80%;
    background-size: 143px, cover;
  }

  .feature {
    height: 360px;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .header-text > h2 {
    width: 132px;
    font-size: 20px;
    line-height: 25px;
    padding-bottom: 1rem;
  }

  .text-container.first {
    width: 100%;
  }

  .main-text {
    font-size: 25px;
    line-height: 30px;
  }

  .first > .main-text,
  .third > .main-text {
    padding-left: 0;
  }

  .first > .sub-text,
  .first > .main-text,
  .third > .sub-text {
    padding-left: 1rem;
  }

  .sub-text {
    font-size: 15px;
    line-height: 20px;
  }

  .first > .sub-text {
    padding-bottom: 2rem;
    background-image: url(../../images/Group\ 31.svg);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: auto;
  }

  .mobile-image {
    display: block;
    padding: 2rem 1rem;
  }

  .second > .mobile-image {
    padding: 0 1rem;
  }

  .second-feature {
    height: 412px;
    flex-direction: row-reverse;
    background-image: url(../../images/Frame\ 62.svg);
    background-repeat: no-repeat;
    /* background-position: 0% 30%; */
    background-position: left;
    background-size: 30%;
  }

  /* .second-feature.feature {
    padding-top: 1rem;
  } */

  .second > .main-text {
    padding-left: 1rem;
  }

  .second > .sub-text {
    width: 100%;
    padding-left: 1rem;
    padding-right: 0rem;
    padding-bottom: 2rem;
    background-image: url(../../images/Group\ 60.svg);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: auto;
  }

  .second > .sub-text > br,
  .third > .sub-text > br {
    display: none;
  }

  .third-feature {
    height: 400px;
    background-image: url(../../images/Group\ 63.svg), var(--bg-color-2);
    background-repeat: no-repeat, no-repeat;
    background-position: top right;
    background-size: auto;
  }

  .third > .main-text {
    padding-left: 1rem;
  }

  .third > .sub-text {
    padding-bottom: 2rem;
    background-image: url(../../images/Group\ 31.svg);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: auto;
  }
}
