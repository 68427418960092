@font-face {
  font-family: 'gerbil';
  src: url('../../font/gerbil.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Neris';
  src: url('../../font/Neris/Neris-SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Neris';
  src: url('../../font/Neris/Neris-Thin.otf');
  font-weight: 300;
}

.contact-header {
  align-items: baseline;
  height: 104px;
  /* margin-bottom: 5rem; */
}

.contact-header > h2 {
  font-size: 50px;
  font-family: 'gerbil', sans-serif;
  color: var(--font-green);
  line-height: 60px;
  padding-left: 1rem;
}

.contact-modes{
  justify-content: space-around;
  padding: 8rem 0;
}

article > img {
  margin-right: 1rem;
}

article > p {
  font-size: 18.8px;
  font-family: 'Neris', sans-serif;
  color: var(--font-color-dark);
}

.subscription {
  margin-top: 5rem;
  text-align: center;
  color: var(--font-white);
  background-image: url(../../images/BG\ 2.png), var(--bg-color-3);
  background-repeat: no-repeat, no-repeat;
  background-position: 0% 0%, left;
  font-family: 'Neris', sans-serif;
  border-radius: 20px;
  padding: 5rem 0;
}

.subscription-header > p:first-child {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  font-family: 'Neris', sans-serif;
  margin: 0.5rem;
}

.subscription-header>p:last-child {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  font-family: 'Neris', sans-serif;
}

.subscription-body {
  margin: 3.8rem 0;
}

.subscription-body > input {
  width:584.07px;
  height: 53.69px;
  border-radius: 50px;
  background-color: var(--font-white);
  outline: none;
  border: none;
  padding: 0 1.5rem;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Neris', sans-serif;
  line-height: 27px;
}
.subscription-body > input::placeholder {
  font-size: 20px;
  font-weight: 300;
  font-family: 'Neris', sans-serif;
}

.subscription-body > input:focus {
  border: 2px solid var(--font-yellow);
}

.subscription-body > button {
  margin-left: 1.5rem;
  width: 133.97px;
  height: 53.69px;
  background: var(--font-yellow);
  backdrop-filter: blur(4px);
  border-radius: 50px;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  cursor: pointer;
  color: var(--font-color-dark);
}

.subscription-footer {
  font-weight: 300;
}

.subscription-footer > p {
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
}

.subscription-footer > p > span {
  color: var(--font-yellow);
}

.subscription-disclaimer {
  justify-content: center;
  margin: 1.5rem;
  font-size: 20px;
  line-height: 27px;
}

.subscription-disclaimer > div > img {
  margin-right: 0.8rem;
}

.disclaimer-left {
  padding-right: 7rem;
}

@media screen and (max-width: 768px) {
  .subscription-body {
    margin: 2.7rem 2rem;
    display: flex;
  }
}

@media screen and (max-width: 640px) {
  .contact-header {
    align-items: center;
    width: 100%;
  }

  .contact-header > h2 {
    padding-left: 1rem;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
  }

  .contact-header > div {
    display: none;
  }

  .contact-modes {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0 5rem 0;
  }

  .contact-modes > article {
    padding: 1rem;
  }

  .subscription {
    border-radius: 8.55px;
    padding: 1.5rem 1rem;
    background-image: url(../../images/BG\ 3.png), var(--bg-color-3);
    background-repeat: no-repeat, no-repeat;
    background-position: 0% 0%, left;
    height: 425px;
  }

  .subscription-header > p:first-child {
    font-size: 20px;
    line-height: 27px;
  }

  .subscription-header>p:last-child {
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    font-family: 'Neris', sans-serif;
  }

  .subscription-body {
    margin: 2.7rem 0;
    display: flex;
    flex-direction: column;
  }

  .subscription-body > input {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    align-self: center;
  }

  .subscription-body>button {
    /* margin: auto; */
    margin-top: 0.7rem;
    color: var(--font-white);
    font-size: 15px;
    line-height: 20px;
    width: 100px;
    height: 50px;
    align-self: flex-end;
  }

  .subscription-footer > p {
    font-size: 12px;
    line-height: 16px;
  }

  .subscription-disclaimer {
    font-size: 12px;
    line-height: 16px;
    margin: 1rem 1rem;
    text-align: center;
  }

  .disclaimer-left {
    padding-right: 0;
    width: 100%;
    justify-content: flex-start;
  }

  .disclaimer-right {
    margin: 0;
    width: 100%;
    justify-content: flex-start;
  }

  .disclaimer-left > img, .disclaimer-right > img {
    max-width: 7.29px;
  }

  .subscription-body>input::placeholder {
    font-size: 15px;
  }
}