.home {
  background-image: url(../../images/Group\ 416.png);
  background-repeat: no-repeat;
  /* background-position: top; */
  background-size: 1519px 4840px;
}

@media screen and (max-width: 2560px) {
  .home {
    background-position: top;
    background-size: 2560px 4840px;
  }
}

@media screen and (max-width: 1440px) {
  .home {
    background-size: 1440px 4840px;
  }
}

@media screen and (max-width: 768px) {
  .home {
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 640px) {
  .home {
    background-image: url(../../images/mobile-bg.png);
    background-repeat: no-repeat;
    /* background-position: top; */
    background-size: 100% 100%;
  }
}

/* @media screen and (max-width: 320px) {
  .home {
    background-size: 320px auto;
  }
} */
