@font-face {
  font-family: 'Neris';
  src: url('../../font/Neris/Neris-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Neris';
  src: url('../../font/Neris/Neris-Black.otf');
  font-weight: 600;
}

.tag {
  box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.36);
  height: 249px;
  /* padding: 4rem 0; */
  text-align: center;
  font-size: 22px;
  font-family: 'Neris', sans-serif;
  font-weight: 300;
  background-image: url(../../images/Frame\ 35.png), url(../../images/Frame\ 36.png), linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  background-repeat: no-repeat, no-repeat;
  background-position: 0% 0%, 100% 100%, center;
  background-size: 300px, 300px, 1005;
}

.tag > p {
  padding-top: 4rem;
}

.exchange {
  font-size: 30px;
  color: var(--font-yellow);
  font-family: 'Neris', sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 1024px) {
  .tag {
    /* width: 100%; */
    background-size: 254px, 220px, auto;
    font-size: 25px;
    height: 150.67px;
    /* background-position: 0% 20%, 100% 100%; */
  }

  .tag>p {
    padding-top: 3rem;
    line-height: 25px;
  }

  .exchange {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .tag {
    width: 100%;
    background-size: 224px, 230px, auto;
    font-size: 25px;
    height: 190.67px;
    /* background-position: 0% 20%, 100% 100%; */
  }

  .tag > p {
    padding-top: 3rem;
    line-height: 25px;
  }

  .exchange {
    font-size: 30px;
  }
}

@media screen and (max-width: 640px) {
  .tag {
    width: 100%;
    background-size: 124px, 100px, auto;
    font-size: 15px;
    height: 90.67px;
    /* background-position: 0% 20%, 100% 100%; */
  }

  .tag > p {
    padding-top: 1rem;
    line-height: 25px;
  }

  .exchange {
    font-size: 20px;
  }
}