.footer {
  padding-top: 3.5rem;
  font-family: "M PLUS 1";
}

.footer-left > img {
  padding-left: 1.4rem;
}

.footer-right > p:not(:last-child) {
  padding-right: 4.5rem;
  font-size: 18px;
}

.footer-right > p > a {
  cursor: pointer;
}

.mobile-footer {
  display: none;
}

@media screen and (max-width: 768px) {
  .footer-right {
    display: none;
  }

  .footer-left {
    padding-top: 1rem;
  }

  .mobile-footer {
    display: block;
  }

  .footer-nav {
    flex-direction: column-reverse;
  }

  .mobile-footer.second,
  .mobile-footer.first {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }

  .mobile-footer.second > p,
  .mobile-footer.first > p {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 640px) {
  .footer-right {
    display: none;
  }

  .footer-left {
    padding-top: 2rem;
  }

  .mobile-footer {
    display: block;
  }

  .footer-nav {
    flex-direction: column-reverse;
  }

  .mobile-footer.second,
  .mobile-footer.first {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }

  .mobile-footer.second > p,
  .mobile-footer.first > p {
    padding: 0 1rem;
  }
}
