:root {
  --font-black: #000;
  --font-yellow: #ffae24;
  --font-white: #fff;
  --font-green: #1f7070;
  --font-color-dark: #041716;
  --bg-color: linear-gradient(
    233.27deg,
    #fca4a1 28.63%,
    rgba(125, 167, 248, 0.47) 101.33%
  );
  --bg-color-2: linear-gradient(
    233.27deg,
    #fcf3a1 28.63%,
    rgba(248, 125, 125, 0.47) 101.33%
  );
  --bg-color-3: linear-gradient(232.91deg, #1f7070 28.61%, #1f4970 88.75%);
  --bg-white: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.4)
  );
  --bg-white1: linear-gradient(
    to right,
    rgba(255, 255, 255),
    rgba(255, 255, 255)
  );
  --bg-popup: linear-gradient(
    233.27deg,
    rgba(83, 99, 183, 0.81) 28.63%,
    #b05623 101.33%
  );
  --bg-sucess-popup: linear-gradient(
    233.27deg,
    #d6a037 28.63%,
    rgba(255, 16, 16, 0.47) 101.33%
  );
}

@font-face {
  font-family: "gerbil";
  src: url("./font/gerbil.otf");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Neris";
  src: url("./font/Neris/Neris-Light.otf");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "M PLUS 1";
  src: url("./font/static/MPLUS1-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "M PLUS 1";
  src: url("./font/static/MPLUS1-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "M PLUS 1";
  src: url("./font/static/MPLUS1-Regular.ttf");
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}
.section-padding {
  padding: 2rem 4rem;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;
  color: var(--font-black);
}

/* #root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0%;
  right: 0;
  overflow: hidden;
  overflow-y: auto;
} */

/* body,
#root {
  overflow: hidden;
} */

@media screen and (min-width: 1440px) {
  .section-padding {
    /* padding: 2rem 6.25rem; */
    padding: 2rem 7.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .section-padding {
    padding: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .section-padding {
    padding: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .section-padding {
    padding: 1rem;
  }
}
