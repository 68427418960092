@font-face {
  font-family: 'gerbil';
  src: url('../../font/gerbil.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Neris';
  src: url('../../font/Neris/Neris-SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Neris';
  src: url('../../font/Neris/Neris-Thin.otf');
  font-weight: 300;
}

.success-content {
  position: absolute;
  background-color: var(--font-white);
  width: 686px;
  height: 549px;
  left: 392.25px;
  top: 91px;
  border-radius: 20px;
  text-align: center;
  background-image: url(../../images/Group\ 418.png), url(../../images/Group\ 29.png);
  background-repeat: no-repeat, no-repeat;
  background-position: 10% 0%, 4% 100%;
}

.success-content>.sucess-close-container {
  position: absolute;
  width: 46.34px;
  height: 46.34px;
  border-radius: 50%;
  left: 688.95px;
  top: -36.79px;
  background-color: var(--font-white);
  cursor: pointer;
}

.success-close {
  display: inline-block;
  padding-top: 0.1rem;
  font-size: 36px;
}

.success-header {
  height: 225px;
  background-image: url(../../images/Group\ 419.png), url(../../images/Group\ 420.png), var(--bg-sucess-popup);
  background-repeat: no-repeat;
  background-position: 39% -25%, 80% -17%;
  border-radius: 20px 20px 0px 0px;
  padding-top: 7rem;
  color: var(--font-white);
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
  font-family: 'gerbil', sans-serif;
  z-index: 2;
}

.success-footer>button {
  width: 133.97px;
  height: 53.69px;
  background-color: var(--font-yellow);
  color: var(--font-white);
  border-radius: 50px;
  margin-left: 2rem;
  box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.25);
  outline: none;
  border: none;
  font-size: 20px;
  line-height: 27px;
  font-family: 'Neris', sans-serif;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 2560px) {
  .success-content {
    width: 50%;
    margin: 0 auto;
    left: 24%;
    top: 20%;
  }

  .success-content>.sucess-close-container {
    position: absolute;
    border-radius: 50%;
    left: 99%;
    top: -5%;
  }

  .success-header {
    font-size: 57px;
    font-weight: 900;
    line-height: 58px;
  }
}

@media screen and (max-width: 1440px) {
  .success-content {
    width: 60%;
    margin: 0 auto;
    left: 17%;
    top: 19%;
  }

  .success-content>.sucess-close-container {
    position: absolute;
    border-radius: 50%;
    left: 97%;
    top: -3%;
  }
}

@media screen and (max-width: 1024px) {
  .success-content {
    width: 70%;
    margin: 0 auto;
    left: 15%;
    top: 25%;
  }

  .success-content>.sucess-close-container {
    position: absolute;
    border-radius: 50%;
    left: 97%;
    top: -3%;
  }
}

@media screen and (max-width: 768px) {
  .success-content {
    width: 90%;
    margin: 0 auto;
    left: 6%;
    top: 25%;
  }

  .success-content>.sucess-close-container {
    position: absolute;
    border-radius: 50%;
    left: 97%;
    top: -3%;
  }
}

@media screen and (max-width: 640px) {
  .success-content {
    width: 90%;
    height: max-content;
    margin: 0 auto;
    border-radius: 10.377px;
    left: 5%;
    top: 25%;
    background-image: url(../../images/Group\ 427.png), url(../../images/Group\ 29.png);
    background-repeat: no-repeat, no-repeat;
    background-position: 8% -9%, 4% 60%;
    background-size: auto, 120px;
  }

  .success-header {
    border-radius: 10.377px 10.377px 0px 0px;
    height: 117px;
    font-size: 25px;
    line-height: 30px;
    padding-top: 3.4rem;
    background-image: url(../../images/Group\ 428.png), url(../../images/Group\ 429.png), var(--bg-sucess-popup);
    background-repeat: no-repeat;
    background-position: 48% -47%, 90% -17%;
  }

  .success-content > .sucess-close-container {
    position: absolute;
    width: 20.34px;
    height: 20.34px;
    border-radius: 50%;
    left: 97%;
    top: -3%;
    background-color: var(--font-white);
    cursor: pointer;
    z-index: 100;
  }

  .success-close {
    display: inline-block;
    padding-bottom: 3rem;
    font-size: 17px;
    font-weight: 900;
    z-index: 101;
  }

  .success-footer>button {
    width: 100px;
    height: 50px;
    border-radius: 19.6835px;
    margin-left: 0rem;
    margin-bottom: 2rem;
  }

  .success-main_text {
    margin-top: 1rem;
  }
}